<template>
  <div>
    <b-card header="铜线/过孔电流计算" header-bg-variant="tmprimary" header-text-variant="white">
      <b-form inline>
        <b-form-select id="input_g1_lunit" v-model="input_std">
          <option value="IPC-2221" selected>新标准/IPC-2221</option>
          <option value="IPC-D-275">旧标准/IPC-D-275</option>
        </b-form-select>
      </b-form>
    </b-card>
  </div>
</template>
<script>
const pi = 3.14159265358979;
export default {
  data() {
    return {
      input_std: "IPC-2221",
    };
  },
  computed: {
  }
};
</script>