<template>
  <div>
    <b-card header="辅助-单位转换" header-bg-variant="tmprimary" header-text-variant="white">
      <b-row>
        <b-col cols="6">
          <b-form inline>
            <b-input-group append="mil">
              <b-form-input id="input_g1_mil" v-model="input_g1_mil" type="text" />
            </b-input-group>
            &nbsp; -> &nbsp;
            <b-input-group append="mm">
              <b-form-input id="output_g1_mm" v-model="output_g1_mm" type="text" readonly />
            </b-input-group>
          </b-form>
        </b-col>
        <b-col cols="6">
          <b-form inline>
            <b-input-group append="mm">
              <b-form-input id="input_g2_mm" v-model="input_g2_mm" type="text" />
            </b-input-group>
            &nbsp; -> &nbsp;
            <b-input-group append="mil">
              <b-form-input id="output_g2_mil" v-model="output_g2_mil" type="text" readonly />
            </b-input-group>
          </b-form>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
const pi = 3.14159265358979;
export default {
  data() {
    return {
      input_g1_mil: "0",
      input_g2_mm: "0"
    };
  },
  computed: {
    output_g1_mm() {
      return Number(this.input_g1_mil) * 0.0254;
    },
    output_g2_mil() {
      return Number(this.input_g2_mm) / 0.0254;
    }
  }
};
</script>