<template>
  <div>
    <TV001 />
    <br />
    <TV002 />
  </div>
</template>
<script>
import TV001 from "@/views/submods/pcb_tvcc/tv001.vue"
import TV002 from "@/views/submods/pcb_tvcc/tv002.vue"

export default {
  components: { TV001, TV002 }
}
</script>